/* @import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,100..900;1,100..900&family=Secular+One&display=swap'); */
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  /* font-family: "Inter Tight"; */
}

body {
  display: flex;
  flex-direction: column;
  width: "100vw";
  font-family: "Inter Tight";
  overflow: -moz-scrollbars-none;
  overflow: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
body::-webkit-scrollbar {
  display: none;
}

.dateRangePickerInput {
  height: 48px;
}

html {
  scrollbar-width: thin; /* Width of the scrollbar */
  scrollbar-color: #c3c3c3 #f1f1f1; /* Scrollbar color and track color */
}

/* ---------Toast message styles---------- */
.Toastify__toast-body {
  padding: 0px !important;
}

.Toastify__toast {
  padding: 0px !important;
}

.Toastify__close-button {
  margin-right: 16px;
  margin-top: 16px;
}

.Toastify__toast-container {
  width: 400px !important;
}

.Toastify__close-button .Toastify__close-button--light {
  color: black !important;
}
/* --------End Toast message styles--------------- */

/* ---Accordian component (Expandable Component Styles)--- */
.MuiAccordion-root{
  box-shadow: none !important;
}
/* ---End Accordian component (Expandable Component Styles)--- */

.ql-editor {
  min-height: 305px !important;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 12px !important;
}

.css-1r9jet7 {
  min-height: 0px !important;
}
/* checkbox input container style */
.css-1m9pwf3 {
  bottom: 0px;
  right: 0px;
  left: 0px !important;
}

/* switch button */
.css-bia2d5-MuiButtonBase-root-MuiSwitch-switchBase .MuiSwitch-input {
  left: 0px !important;
}

/* radio button lable style */
.css-w7cxa7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  color: black !important;
}

a {
  text-decoration: none;
  color: inherit;
}

/* ---React Calender Styles--- */
.react-calendar {
  margin-top: 24px;
  width: 100% !important;
  border-radius: 16px;
  border: 1px solid #f1f2f4 !important;
}

.react-calendar__tile {
  height: 100px;
  border: 1px solid #f1f2f4 !important;
}

.react-calendar__tile abbr {
  text-align: end;
}
/* --End React Calender Styles--- */

/* upload icon */
.uploadIconWhite path {
  stroke: white;
}

/* Hide scrollbar but not functionality */
/* Hide scrollbar for Chrome, Safari and Opera */
.HideScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.HideScrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background: transparent;
}

.HideScrollbar::-webkit-scrollbar {
  display: none;
  width: 0;
  background: transparent;
}

::-webkit-scrollBar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

.HideScrollbar {
  /* scrollbar-width: thin; /*For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.HideScrollbar::-webkit-scrollbar-thumb {
  display: none; /* For WebKit browsers */
}

.Collapsible {
  width: 100% !important;
}

/* Custom Phone Input */
.PhoneInputCountrySelect {
  appearance: none !important;
  display: inline-block;
  width: 10px;
  height: 10px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  opacity: 0;
  height: 100% !important;
}
.PhoneInputCountryIconImg {
  width: 24px;
  height: 18px;
}

.PhoneInput {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 100% !important;
  padding: 0px 20px !important;
  border-radius: 12px;
  gap: 12px;
  width: 100%;
}

.PhoneInputCountrySelect {
  width: 20px;
  height: 100%;
  border: none;
  outline: none;
  box-shadow: none !important;
  background-color: transparent;
  z-index: 20 !important;
}

.input-phone-number input:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.PhoneInputCountry {
  display: flex;
  align-items: center !important;
  flex-direction: row-reverse;
  align-self: center;
  height: 100%;
  gap: 8px;
  z-index: 20 !important;
}

.PhoneInputInput {
  height: 100% !important;
  padding: 16px 0px !important;
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
}

.PhoneInputCountryIcon {
  display: flex;
  align-items: center;
  width: 18px;
}

input:disabled {
  background-color: inherit;
}

a {
  list-style-type: none !important;
  text-decoration: none !important;
}

/* Time picker */
.tp_outerWrapper {
  width: 100% !important;
}

.tp_mainWrapper {
  width: 100% !important;
}

.tp_inputWrapper {
  width: 100% !important;
  border: 1.6px solid #e9eaec !important;
  border-radius: 12px !important;
  padding: 0px 0px 0px 0px !important;
}

.tp_inputWrapper:visited {
  border: 1px solid red !important;
}

.tp_inputBox {
  width: 100% !important;
  padding: 13px 20px !important;
  /* text-align: center; */
}

.tp_closeIcon {
  width: 15px !important;
  height: 15px !important;
}

.tp_secondWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tp_secondWrapper {
  display: none !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tp_minutesWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tp_minutesWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.tp_hoursWrapper::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tp_hoursWrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.PrivateSwitchBase-input .css-1m9pwf3 {
  border-radius: 24px !important;
}
/* Input type time  */
.rs-picker-toggle-textbox > div {
  font-size: 14px !important;
  padding-left: 18.8px !important;
  font-weight: 500;
  color: grey !important;
}

.rs-picker-toggle-textbox {
  font-size: 14px !important;
  padding-left: 18.8px !important;
  font-weight: 500;
  color: grey !important;
}

.rs-picker-toggle-read-only {
  font-size: 14px !important;
  padding-left: 18.8px !important;
  font-weight: 500;
}

#pdf-doc iframe {
  margin: 0 auto;
  width: 80% !important;
  height: 100% !important;
}

.css-e0k9p-MuiButtonBase-root-MuiRadio-root {
  color: 1px solid #e9eaec !important;
}

/* Date input styles */
.datepicker-toggle {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 54px;
  box-shadow: inset 0px 0px 0px 1px #3D3D3D;
  border-radius: 12px;
  border: none !important;
}
.datepicker-toggle-button {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  border-radius: 16px;
  height: 100%;
  /* background-image: url('data:image/svg+xml;base64,...'); */
}

.datepicker-input {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
  padding-left: 20px;
  border: none;
  border-radius: 12px;
}
.datepicker-input::placeholder {
  color: #a0aec0;
}

.datepicker-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  /* left: 16px; */
  /* top: 0; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: pointer;
}

/* Sidebar item list text */
.css-81v4we {
  /* width:"100%" */
}

.center {
  justify-content: center;
  align-items: center;
  display: flex;
}

.pointer {
  cursor: pointer;
}

.slick-dots li.slick-active button:before {
  color: #80c242 !important;
}

.slick-dots li {
  width: 24px !important;
  height: 24px !important;
  margin: 0 -6px !important;
}

.slick-prev,
.slick-next {
  top: 45% !important;
  margin: 4px !important;
}

.slick-next {
  right: -20px !important;
}

.slider {
  padding-top: 16px;
}

.breadcrumb a {
  font-size: 24px;
  color: #ffffff !important;
}

.breadcrumb a:hover {
  color: #ffffff !important;
}

.centered-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rc-tooltip {
  z-index: 1000000 !important;
  opacity: 1 !important;
  border-radius: 53px !important;
  background-color: white !important;
  margin-bottom: 125px !important;
}
.rc-tooltip-inner {
  padding: 0px !important;
  border-radius: 16px !important;
  border: none !important;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.12) !important;
}

.rc-tooltip-arrow {
  display: none !important;
}

.capitalize {
  text-transform: capitalize;
}

.rti--container {
  border-radius: 12px;
  padding: 12px;
  border-width: 1px !important;
}

.rti--input:focus {
  outline: none !important;
  border-width: 1px !important;
}

.rti--container {
  --rti-main: #80c242 !important;
  padding: 12px !important;
  border-radius: 12px !important;
  border-width: 1px !important;
}

.rs-picker-daterange-menu {
  left: 34% !important;
}

/* MUI table body */
.MuiTableBody-root {
  border: "1px solid red";
  max-height: "200px";
}

.MuiPopover-root {
  z-index: 500000 !important;
}

.react-datepicker-popper {
  z-index: 500000 !important;
}

/* Toast message box styles */
.Toastify__toast-theme--dark  {
  background: #1B1B1B !important;
}

/* Reserve space for the scrollbar but hide it by default */
.custom-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */
}
.custom-scrollbar::-webkit-scrollbar {
  width: 10px; /* For Chrome, Safari, and Opera */
  height: 10px;
  background: transparent; /* Hide the scrollbar */
  border-radius: 4px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: transparent; /* Hide the scrollbar thumb */
  border-radius: 4px;
}
/* Show scrollbar when hovering */
.custom-scrollbar:hover {
  scrollbar-color: #c3c3c3 #f1f1f1; /* Scrollbar color and track color */
  scrollbar-width: thin; 
}
.custom-scrollbar:hover::-webkit-scrollbar {
  background: #f1f1f1; /* Show the scrollbar track */
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb {
  background: #b1b0b0; /* Show the scrollbar thumb */
  scrollbar-width: thin; 
}
.custom-scrollbar:hover::-webkit-scrollbar-thumb:hover {
  background: #b1b0b0; /* Show the scrollbar thumb on hover */
  scrollbar-width: thin; 
}